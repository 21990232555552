export function updateDineroRecibidoFletes(){
    var subTotalSueldoComision = 0;
    var defaultAmount = parseFloat(document.getElementById("totalFleteGeneralEditar").value);
    var fecha = document.querySelectorAll("#extraFieldsFleteGeneral input[type='date']");
    var amountsSueldoComision = document.querySelectorAll("#extraFieldsFleteGeneral input[type='number']");
    for (var i = 0; i < fecha.length; i++) {
        var indexTotal = (i * 7) + 3;
        subTotalSueldoComision = subTotalSueldoComision + parseFloat(amountsSueldoComision[indexTotal].value);
    }
    document.getElementById("amountCuentasGenerales1").value = subTotalSueldoComision + defaultAmount;
    updateGananciasDelEquipo();
}

export function updateGastosGeneralesEnLaVuelta(){
    var casetas = parseFloat(document.getElementById("totalAmountCasetasEditar").value);
    var gastosRegulares = parseFloat(document.getElementById("totalAmountGastosRegularesEditar").value);
    var otrosGastos = parseFloat(document.getElementById("totalAmountOtrosGastosEditar").value);
    var diesel = parseFloat(document.getElementById("totalPrecioDieselEditar").value);
    var sueldoComisionOperador = parseFloat(document.getElementById("totalAmountSueldoComision").value);
    var total = (casetas + gastosRegulares + otrosGastos + diesel + sueldoComisionOperador);
    document.getElementById("amountCuentasGenerales2").value = total;
    updateGananciasDelEquipo();
}

function updateGananciasDelEquipo(){
    var totalDineroRecibidoFletes = parseFloat(document.getElementById("amountCuentasGenerales1").value);
    var totalGananciasEnLaVuelta = parseFloat(document.getElementById("amountCuentasGenerales2").value);
    document.getElementById("amountCuentasGenerales3").value = totalDineroRecibidoFletes - totalGananciasEnLaVuelta;
}

export function getInformacionCuentasGenerales(){
    var descripcionCuentasGenerales1 = document.getElementById("descripcionCuentasGenerales1").value;
    var amountCuentasGenerales1 = document.getElementById("amountCuentasGenerales1").value;
    var descripcionCuentasGenerales2 = document.getElementById("descripcionCuentasGenerales2").value;
    var amountCuentasGenerales2 = document.getElementById("amountCuentasGenerales2").value;
    var descripcionCuentasGenerales3 = document.getElementById("descripcionCuentasGenerales3").value;
    var amountCuentasGenerales3 = document.getElementById("amountCuentasGenerales3").value;
    var observacionesCuentasOperador = document.getElementById("observacionesCuentasOperadorLiquidacionGeneral").value;

    var informacionCuentasGenerales = {
        descripcionCuentasGenerales1: descripcionCuentasGenerales1,
        amountCuentasGenerales1: amountCuentasGenerales1,
        descripcionCuentasGenerales2: descripcionCuentasGenerales2,
        amountCuentasGenerales2: amountCuentasGenerales2,
        descripcionCuentasGenerales3: descripcionCuentasGenerales3,
        amountCuentasGenerales3: amountCuentasGenerales3,
        observacionesCuentasOperador: observacionesCuentasOperador
    }

    return informacionCuentasGenerales;

}